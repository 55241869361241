import { usePage, Link } from "@inertiajs/react";
import {
    NavigationMenu,
    NavigationMenuIndicator,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import type { PageProps } from "@/types/app.d.ts";

export function MainNavigationMenu() {
    const page = usePage<PageProps>();

    return (
        <NavigationMenu>
            <NavigationMenuList>
                {page.props.auth.user ? (
                    <NavigationMenuItem>
                        <NavigationMenuLink
                            className={navigationMenuTriggerStyle()}
                            asChild
                        >
                            <Link
                                href={route("logout")}
                                method="post"
                                as="button"
                            >
                                Log out
                            </Link>
                        </NavigationMenuLink>
                    </NavigationMenuItem>
                ) : (
                    <NavigationMenuItem>
                        <NavigationMenuLink
                            className={navigationMenuTriggerStyle()}
                            asChild
                        >
                            <Link href={route("login")}>Log in</Link>
                        </NavigationMenuLink>
                    </NavigationMenuItem>
                )}
                <NavigationMenuIndicator forceMount />
            </NavigationMenuList>
        </NavigationMenu>
    );
}
