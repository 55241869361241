import { ReactNode } from "react";
import { Toaster } from "@/components/ui/toaster.tsx";
import { MainNavigationMenu } from "@/components/layout/navigation-menu.tsx";
import { Link } from "@inertiajs/react";

export function RootLayout({ children }: { children: ReactNode }) {
    return (
        <>
            <div className="h-screen bg-muted flex flex-col">
                <div className="flex justify-between items-center p-1 bg-card shadow z-10">
                    <div>
                        <div className="w-28">
                            <Link href={route("home")} target="_blank">
                                <img src="/logo.svg" alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <MainNavigationMenu />
                </div>
                <div
                    id="root-layout-scroll-container"
                    className="flex flex-col flex-1 relative min-h-0 p-4 px-1 overflow-auto"
                >
                    <div className="relative mx-auto w-full md:w-[600px] flex-1 mb-12">
                        {children}
                    </div>
                    <p className="text-center text-xs text-muted-foreground mt-2">
                        Copyright © 2024 Apasas Health
                    </p>
                </div>
            </div>
            <Toaster />
        </>
    );
}
