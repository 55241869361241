import { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { RootLayout } from "@/components/layout/root-layout.tsx";
import { TooltipProvider } from "@/components/ui/tooltip.tsx";
import "./bootstrap";
import "../css/global.css";

type Page = {
    default: {
        layout: (page: ReactNode) => ReactNode;
    };
};

createInertiaApp({
    title: (title) => `${title} - Apasas Health`,
    resolve: async (name) => {
        const page = (await resolvePageComponent(
            `./pages/${name}.tsx`,
            import.meta.glob("./pages/**/*.tsx"),
        )) as Page;
        page.default.layout = (page: ReactNode) => (
            <RootLayout>{page}</RootLayout>
        );
        return page;
    },
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(
            <TooltipProvider>
                <App {...props} />
            </TooltipProvider>,
        );
    },
    progress: {
        color: "#4B5563",
    },
});
